import "./App.css";
import { useEffect, useState } from "react";

import ImageListItem from "@mui/material/ImageListItem";

import { alpha, SvgIcon } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Masonry from "@mui/lab/Masonry";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import ImageList from "@mui/material/ImageList";
import Stack from "@mui/material/Stack";
import Icon from "./components/Icon";
import createSvgIcon from "@mui/material/utils/createSvgIcon";

import Particles from "./components/Particles";

function importAll(r) {
  return r.keys().map(r);
}

// const images = require.context("./images", true);
// const imageList = images.keys().map((image) => images(image));

const imageList = importAll(
  require.context("./images", false, /\.(png|jpe?g|svg)$/)
);

function App() {
  const SteamIcon = createSvgIcon(Icon(), "SteamIcon");

  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  };

  const getColumns = (width) => {
    if (width < breakpoints.sm) {
      return 1;
    } else if (width < breakpoints.md) {
      return 2;
    } else if (width < breakpoints.lg) {
      return 3;
    } else if (width < breakpoints.xl) {
      return 3;
    } else {
      return 3;
    }
  };

  const [columns, setColumns] = useState(getColumns(window.innerWidth));
  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="App">
      <Box sx={{}}>
        {/* Background effect */}
        <Particles sx={{ zIndex: -10 }} />

        {/* Navbar */}

        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // pt: { xs: 5, sm: 4 },
            pb: { xs: 2, sm: 2 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontFamily: "ArtySignature",
              fontSize: "clamp(12rem, 5vw, 20rem)",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",

              color: "#010042",
              zIndex: 1,
              padding: 0,
              margin: 0,
              pt: 5,
              pb: 5,
            }}
          >
            bahbot
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="contained"
                size="small"
                href="https://twitter.com/bahbotato"
                target="_blank"
                sx={{
                  mt: 0,
                  mb: 3,
                  alignSelf: "center",
                  width: 40,
                  color: "#010042",
                  backgroundColor: alpha("#FFFFFF", 0.1),
                  "&:hover": {
                    backgroundColor: alpha("#FFFFFF", 0.4),
                  },
                }}
              >
                <XIcon sx={{ fontSize: 30, padding: 0.5 }} color="#010042" />
              </Button>
              <Button
                variant="contained"
                size="small"
                href="https://steamcommunity.com/id/bahbot/"
                target="_blank"
                sx={{
                  mt: 0,
                  mb: 3,
                  alignSelf: "center",
                  width: 40,
                  color: "#010042",
                  backgroundColor: alpha("#FFFFFF", 0.1),
                  "&:hover": {
                    backgroundColor: alpha("#FFFFFF", 0.4),
                  },
                }}
              >
                <SteamIcon
                  sx={{ fontSize: 30, padding: 0.5 }}
                  color="#010042"
                />
              </Button>
            </Stack>
          </Typography>
        </Container>
      </Box>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {/* <Masonry
          id="list"
          columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        > */}

        <ImageList
          variant="masonry"
          cols={columns}
          sx={{
            ml: 2,
            mr: 2,
          }}
        >
          {imageList.map((image, index) => (
            <ImageListItem key={index}>
              <img
                key={index}
                srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${image}?w=248&fit=crop&auto=format`}
                alt={`image-${index}, ${image.default}`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        {/* </Masonry> */}
      </Box>
    </div>
  );
}

export default App;
